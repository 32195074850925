import React from 'react';
import { Helmet } from "react-helmet";

const Title = ({ title, description, keyword, img }) => {
    return (
        <Helmet>
            <title> {title} | Bright Future Soft</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keyword} />
            <meta name="author" content="Bright Future Soft" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content="Bright Future Soft is a software company based in Dhaka, Bangladesh, specializing in web development, mobile app development, and UI/UX design. We also offer software consulting services to help businesses optimize their processes and achieve their goals. With our cutting-edge technology solutions, we can help you build a brighter future for your business." />
            <meta property="og:image" content={img} />
        </Helmet>
    );
};

export default Title;